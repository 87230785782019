const START = 'start'
const SUCCESS = 'success'
const ERROR = 'error'
const CANCEL = 'cancel'

type Suffix = typeof START | typeof SUCCESS | typeof ERROR | typeof CANCEL
export type DurationKey = `${string}${Suffix | Uppercase<Suffix>}`

/**
 * Calculate and retrieve the duration in milliseconds for a specific performance event.
 * The provided key must be a string that ends with one of the following: start, success, error, cancel.
 * This convention is used to calculate the duration using the browser API performance.mark(key).
 *
 * **Keys are shared across the application, so it's crucial to use a unique key.**
 *
 * Returns the duration in milliseconds:
 * - null = check that the keys are correct,
 * - -1 = ensure that start is called before success, error or cancel.
 *
 * @example
 *
 * logger.info('Payment start', {
 *    type: 'PAYMENT_START',
 *    duration: getDuration(PaymentLogTypes.FORM_SUBMIT_START), // = 0
 * })
 * await paymentService.submitForm()
 * logger.info('Payment success', {
 *   type: 'PAYMENT_SUCCESS',
 *   duration: getDuration(PaymentLogTypes.FORM_SUBMIT_SUCCESS), // = X ms
 * })
 */
export const getDuration = (key: DurationKey) => {
  try {
    const lowerCaseKey = key.toLowerCase()

    if (lowerCaseKey.endsWith(START)) {
      const mark = performance.mark(lowerCaseKey)

      // = 0
      return mark.duration
    }
    if (
      [SUCCESS, ERROR, CANCEL].reduce(
        (acc, curr) => acc || lowerCaseKey.endsWith(curr),
        false,
      )
    ) {
      performance.mark(lowerCaseKey)

      const regex = new RegExp(`(${START}|${SUCCESS}|${ERROR}|${CANCEL})$`)
      const startKey = lowerCaseKey.replace(regex, START)
      const measureKey = lowerCaseKey.replace(regex, 'measure')

      // Will throw an error if the start mark doesn't exist
      const measure = performance.measure(measureKey, startKey, lowerCaseKey)

      return measure.duration
    }

    // null = no duration available
    return null
  } catch {
    // do nothing
    return -1
  }
}

/**
 * Check if the key is a valid key to call `getDuration`.
 * This is useful for type narrowing.
 */
export const isDurationKey = (key: string): key is DurationKey => {
  const lowerCaseKey = key.toLowerCase()

  return (
    lowerCaseKey.endsWith(START) ||
    lowerCaseKey.endsWith(SUCCESS) ||
    lowerCaseKey.endsWith(ERROR) ||
    lowerCaseKey.endsWith(CANCEL)
  )
}
