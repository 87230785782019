import { type MaybeRefOrGetter, toRaw, toValue } from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { get } from '@backmarket/utils/object/get'
import {
  createSharedComposable,
  promiseTimeout,
  useScriptTag,
} from '@vueuse/core'

import type {
  GooglePay,
  GooglePayIsReadyToPayRequest,
  GooglePayPaymentDataRequest,
  GooglePayPaymentsClient,
  GooglePayTransactionInfo,
} from '../../../../types/google-pay'
import { getDuration } from '../../../../utils/performance/getDuration'
import { PaymentError } from '../../form-common'
import { isValidGooglePayPaymentMethodConfig } from '../../form-common/helpers/isValidConfig'
import { PaymentMethodMisconfiguredError } from '../../form-common/types/PaymentMethodMisconfiguredError'

const GOOGLE_PAY_LIBRARY_URL = 'https://pay.google.com/gp/p/js/pay.js'
const GOOGLE_PAY_READY_TIMEOUT = 800

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

type GoogleEventKey = 'library' | 'is_ready' | 'is_ready_to_pay'
type State = 'start' | 'success' | 'error'

function useDurationLogger(
  logger: ReturnType<typeof useLogger>,
  method: MaybeRefOrGetter<PaymentMethod | null>,
) {
  const { userId: clientId } = useAuthStore()

  function log(
    key: GoogleEventKey,
    state: State,
    data?: Record<string, unknown>,
  ) {
    const duration = getDuration(`${key}_${state}`)

    const payload = {
      clientId,
      type: `${key}_${state}`.toUpperCase(),
      payment: {
        method: toValue(method),
        event: { key, state },
        ...(state !== 'start' ? { duration } : {}),
        ...data,
      },
    }

    logger.info(`[PAYMENT] [GooglePay] ${key}_${state}`, payload)
  }

  return log
}

function useGooglePayScript(
  log: ReturnType<typeof useDurationLogger>,
): Promise<GooglePay> {
  log('library', 'start')

  return new Promise((resolve) => {
    useScriptTag(GOOGLE_PAY_LIBRARY_URL, () => {
      log('library', 'success')
      resolve(window.google)
    })
  })
}

export const useGooglePay = createSharedComposable(
  (method: MaybeRefOrGetter<PaymentMethod | null>) => {
    const logger = useLogger()
    const log = useDurationLogger(logger, method)
    const googlePayScript = useGooglePayScript(log)

    function rawConfig() {
      const raw = toRaw(toValue(method))?.config

      if (isValidGooglePayPaymentMethodConfig(raw)) {
        return raw
      }

      throw new PaymentMethodMisconfiguredError(
        'Invalid Google Pay configuration',
      )
    }

    let paymentsClient: GooglePayPaymentsClient
    async function getPaymentsClient() {
      if (!paymentsClient) {
        const { environment } = rawConfig()
        const library = await googlePayScript

        paymentsClient = new library.payments.api.PaymentsClient({
          environment,
        })
      }

      return paymentsClient
    }

    function getIsReadyToPayRequest(): GooglePayIsReadyToPayRequest {
      const { type, parameters } = rawConfig()

      return {
        ...BASE_REQUEST,
        allowedPaymentMethods: [{ type, parameters }],
        existingPaymentMethodRequired: true,
      }
    }

    function getPaymentDataRequest(
      transactionInfo: GooglePayTransactionInfo,
    ): GooglePayPaymentDataRequest {
      const { type, parameters, tokenizationSpecification, merchantInfo } =
        rawConfig()

      return {
        ...BASE_REQUEST,
        transactionInfo,
        merchantInfo,
        allowedPaymentMethods: [
          { type, parameters, tokenizationSpecification },
        ],
      }
    }

    async function isReadyToPay() {
      try {
        const client = await getPaymentsClient()

        log('is_ready_to_pay', 'start')

        const request = getIsReadyToPayRequest()
        const { result, paymentMethodPresent } =
          await client.isReadyToPay(request)

        if (!result) {
          throw new PaymentError('Google Pay is not ready to pay', {
            type: '/errors/payment/google-pay-not-ready',
          })
        }

        if (!paymentMethodPresent) {
          throw new PaymentError('User has not payment method registered', {
            type: '/errors/payment/google-pay-no-payment-method',
          })
        }

        log('is_ready_to_pay', 'success')
      } catch (error) {
        log('is_ready_to_pay', 'error', { error })

        throw error
      }
    }

    async function isReady(useTimeout = true) {
      try {
        log('is_ready', 'start')

        const promises: Promise<void>[] = [isReadyToPay()]

        if (useTimeout) {
          promises.push(
            promiseTimeout(GOOGLE_PAY_READY_TIMEOUT).then(() => {
              throw new PaymentError('Google Pay isReady timeout', {
                type: '/errors/payment/google-pay-is-ready-timeout',
              })
            }),
          )
        }

        await Promise.race(promises)

        log('is_ready', 'success')
      } catch (error) {
        log('is_ready', 'error', { error })
        throw error
      }
    }

    /**
     * Collects browser info for 3DS
     *
     * Required by Aduen
     * @see https://docs.adyen.com/api-explorer/Checkout/latest/post/payments#request-browserInfo
     *
     * This code is a temporary solution until we implement the GooglePay Adyen component copied from :
     * @see https://github.com/Adyen/adyen-web/blob/0ebf396b6416cac0b195fa1516d6a023d3f92728/packages/lib/src/utils/browserInfo.ts
     *
     * TODO: Implement GooglePay Adyen component instead of using the GooglePay API
     * @see https://docs.adyen.com/payment-methods/google-pay/web-component
     */
    function collectBrowserInfo() {
      const colorDepth = get(window, 'screen.colorDepth') || ''
      const javaEnabled = get(window, 'navigator.javaEnabled')
        ? window.navigator.javaEnabled()
        : false
      const screenHeight = get(window, 'screen.height') || ''
      const screenWidth = get(window, 'screen.width') || ''
      const userAgent = get(window, 'navigator.userAgent') || ''

      const language = get(window, 'navigator.language') || 'en'
      const d = new Date()
      const timeZoneOffset = d.getTimezoneOffset()

      return {
        acceptHeader: '*/*',
        colorDepth,
        language,
        javaEnabled,
        screenHeight,
        screenWidth,
        userAgent,
        timeZoneOffset,
      }
    }

    return {
      isReady,
      getPaymentsClient,
      getIsReadyToPayRequest,
      getPaymentDataRequest,
      collectBrowserInfo,
    }
  },
)

export type UseGooglePay = ReturnType<typeof useGooglePay>
